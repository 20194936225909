import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {navigate} from "gatsby";
import {useIsMobile} from "../utils";
import {AnimatePresence, motion} from "framer-motion";
import Hero from "../components/hero";
import Story from "../components/story";
import Contact from "../components/contact";


const WholeSale = ({
                   data,
                   pageContext: {nextPagePath, previousPagePath},
               }) => {
    const {isMobile} = useIsMobile();
    return (
      <div>
            <Seo title="商業批發"/>
            <Hero imageUrl="/wholesale-pic1.jpg"/>
            <div
                style={{
                    padding: '4rem 4rem 0 4rem',
                    maxWidth: 657,
                    textAlign: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0px auto',
                }}
            >
                WHOLESALE
                <br/>
                <div style={{fontSize: 40}}>
                    商業批發
                </div>
                <div style={{textAlign: 'justify', padding: isMobile ? '0 16px' : '64px 0'}}>
                    我們喜歡與喜歡我們咖啡豆的客戶合作，不管是辦公室、咖啡廳，或著是飯店與民宿，
                    都可以成為我們的合作夥伴。
                    合作咖啡致力於提供新鮮的咖啡豆給喜歡我們的客戶，品質的把關一直是我們的堅持，
                    如還需要更多關於商業批發的資訊，歡迎填寫以下的表單，我們會在兩天內與您聯繫。
                </div>
            </div>
            <br/>
            <div style={{ maxWidth: 1300, margin: '0px auto'}}>
                <Story
                  imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/wholesale-pic2.png"
                    header=""
                    content={<p>
                        我們提供民宿以及飯店的商業配合，提供房間內的掛耳式咖啡，讓旅人在旅途中，也可以喝到一杯好咖啡。
                        我們會依據民宿與飯店的需求，挑選適合的咖啡豆做成掛耳式咖啡，不用擔心新鮮度的問題，品質是我們嚴格把關的重要環節！
                    </p>}
                />
                <div style={{ height: 64}} />
                <Story
                    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/wholesale-pic3.png"
                    header=""
                    direction="left"
                    content={<p>
                        我們也非常樂意提供優質的咖啡豆給咖啡店家以及辦公室，
                        讓世界各地喜歡我們的咖啡店家或辦公室，可以品嘗到我們所配送的新鮮咖啡豆。

                    </p>}
                />
            </div>
            <div style={{ height: 64}} />
            <Contact />
        </div>
    )
}

export default WholeSale;
